import { FunctionShim } from '@packages/helpers/core/shims/function-shim';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { StringShim } from '@packages/helpers/core/shims/string-shim';
import { parseUrl, stringifyUrl } from 'query-string';
import { NativeAction } from '../services/native-action';
import { ROUTE_SEPARATOR } from './constants';
import { fromMode } from './navigation/routing';

export const openLink = (to, history, isExternal = false, openNewTab = true) => {
  if (!to) {
    return;
  }

  if (isExternal) {
    const pathname = to.pathname ? to.pathname : to;

    if (window.webkit && window.webkit.messageHandlers.cordova_iab) {
      return NativeAction.trigger('external', { url: pathname });
    }

    return window.open(pathname, openNewTab ? '_blank' : '_self');
  }

  if (StringShim.isString(to) && to.startsWith('http')) {
    return (window.location = to);
  }

  if (ObjectShim.isObject(to) && to.pathname?.includes('?')) {
    const parsed = parseUrl(to.pathname);
    const search = stringifyUrl({ url: '', query: parsed.query });

    to.pathname = parsed.url;
    to.search = search;
  }

  const parsed = parseUrl(to.pathname ?? to, { parseFragmentIdentifier: true });
  if (parsed.fragmentIdentifier) {
    const search = stringifyUrl({ url: '', query: parsed.query });
    const redirect = {
      pathname: parsed.url,
      search,
      hash: parsed.fragmentIdentifier
    };

    return history.push(redirect);
  }

  return history.push(fromMode(to));
};

export const getRoutesByPathname = (pathname = ROUTE_SEPARATOR) => {
  if (pathname.startsWith(ROUTE_SEPARATOR)) {
    return pathname.replace(ROUTE_SEPARATOR, '').split(ROUTE_SEPARATOR);
  }

  return pathname.split(ROUTE_SEPARATOR);
};

export const getBasePathFromRoutes = ([basePath = '']) => ROUTE_SEPARATOR.concat(basePath);

export const createCustomPageProps = (pathname, separator = '-') => {
  const [, name, trigger] = getRoutesByPathname(pathname);

  return {
    name,
    trigger,
    full: name + separator + trigger
  };
};

export const changeLastPartOfPath = (pathname, newPart) => pathname.split('/').slice(0, -1).join('/') + newPart;

export const setHandler = (element, type, callback, options = false) => {
  if (element && 'addEventListener' in element) {
    element.addEventListener(type, callback, options);

    return () => element.removeEventListener(type, callback, options);
  }
};

export const removeHandlers = (handlers = {}) => {
  return Object.keys(handlers).reduce((handlers, event) => {
    const unlisten = handlers[event];

    if (FunctionShim.isFunction(unlisten)) {
      unlisten();

      return { ...handlers, [event]: null };
    }

    return handlers;
  }, handlers);
};

export const downloadFile = ({ blob, name }) => {
  let a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
};
