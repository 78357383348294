import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { PROVIDER_TYPES } from './constants';

export const getSortedMarketplace = (marketplace, maxCount) => {
  const items = marketplace
    .map(group => {
      if (group.type === PROVIDER_TYPES.STANDALONE) {
        return group;
      }

      return {
        ...group,
        partners: group.partners.sort((a, b) => a.weight - b.weight)
      };
    })
    .sort((a, b) => (a.marketPlaceWeight || a.weight) - (b.marketPlaceWeight || b.weight));

  return ArrayShim.take(items, maxCount);
};

export const normalizePartners = (partners = []) => {
  return partners.reduce((map, entity) => {
    const { key, type, partners } = entity;

    if (type === 'group' && Array.isArray(partners)) {
      return {
        ...map,
        ...normalizePartners(partners)
      };
    }

    return { ...map, [key]: entity };
  }, {});
};

export const getPartnerTrustCard = (partners = {}, partnerKey) => {
  const partner = partners[partnerKey];

  if (partner) {
    const { name, logoUrl, descriptionShort, valueAlignment, commercialDeal, regulator } = partner;

    return {
      ...(logoUrl && { logo: { url: logoUrl, alt: descriptionShort } }),
      name,
      valueAlignment,
      commercialDeal,
      regulator
    };
  }

  return null;
};
