import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import styles from '../../styles/components/page-footer.module.scss';
import { AffiliateIcon } from '../icons/affiliate-icon';
import { Link } from '../link';
import { AffiliateImage } from '../images/image';
import { ConfigService } from '../../services/config-service';
import { Grid, Column } from './grid';
import { PurifiedHtmlContainer } from './purified-html-container';

const FooterContainer = React.memo(({ children, className }) => (
  <div className={classnames(styles.container, className)}>
    <Grid>{children}</Grid>
  </div>
));

export const Text = React.memo(({ children, ...props }) => (
  <div className={styles['text-container']}>
    {props?.html && (
      <PurifiedHtmlContainer className={classnames('typography', styles.text)} {...props}>
        {children}
      </PurifiedHtmlContainer>
    )}
  </div>
));

const LegalText = props => <Text {...props} />;

export const LegalFooter = React.memo(({ className, ...props }) => (
  <FooterContainer className={className}>
    <Column>
      <LegalText {...props} />
    </Column>
  </FooterContainer>
));

export const Footer = React.memo(({ pageType }) => {
  const {
    header,
    socials,
    logoLink,
    isLogoLinkExternal = false,
    buttons,
    legalText,
    isVisible
  } = ConfigService.get('FOOTER', {});

  if (!isVisible[pageType]) return null;

  return (
    <footer>
      <FooterContainer className={styles.homepage}>
        <Column>
          <div className={styles.body}>
            {header && (
              <section className={styles.header}>
                <p className={styles.title}>{header}</p>
              </section>
            )}
            {socials && (
              <ul className={styles.icons}>
                {socials.map(({ icon, link, ariaLabel }) => (
                  <li key={link} className={styles.icon}>
                    <Link to={link} ariaLabel={ariaLabel} isExternal>
                      {icon ? <AffiliateIcon name={icon} /> : null}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
            {logoLink && (
              <section className={styles['logo-container']}>
                <Link to={logoLink} isExternal={isLogoLinkExternal}>
                  <AffiliateImage className={styles.logo} name='footer-logo' extension='png' />
                </Link>
              </section>
            )}
            {buttons && (
              <Grid renderAs='ul' className={styles.buttons} fill>
                {buttons.map(({ labelText, link, isExternal, icon }) => (
                  <li key={labelText} className={styles['button-list-item']}>
                    <Link to={link} ariaLabel={labelText} isExternal={isExternal} className={styles.button}>
                      <div className={styles['button-content']}>
                        {icon ? <AffiliateIcon name={icon} className={styles['button-icon']} /> : null}
                        <span className={styles.button}>{labelText}</span>
                      </div>
                    </Link>
                  </li>
                ))}
              </Grid>
            )}
            {!!legalText && (
              <div className={styles.footer}>
                <LegalText html={legalText} />
              </div>
            )}
          </div>
        </Column>
      </FooterContainer>
    </footer>
  );
});

Footer.displayName = 'Footer';

Footer.propTypes = {
  pageType: PropTypes.string.isRequired
};
