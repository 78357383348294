import { branch, compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { sendApplicationData } from '../../../store/reducers/user-attributes/actions';
import { getPoints, getRewardCards } from '../../../store/reducers/points-and-awards';
import { withTrackingHandlers } from '../../../helpers/hocs/with-tracking-handlers';
import { withLoader, withLoadingHandlers } from '../../with-loader';
import { withEmbeddedContext } from '../with-embedded-context';
import { withDefaultContent } from './with-default-content';
import { withEmbeddedContent } from './with-embedded-content';
import { withScroll } from './with-scroll';
import { withNativeLoadingTrigger } from './with-native-loading-trigger';

export const withContent = compose(
  connect(null, {
    getPoints,
    getRewardCards,
    sendApplicationData
  }),
  withRouter,
  withEmbeddedContext,
  withLoadingHandlers(true),
  withTrackingHandlers,
  branch(({ isEmbedded }) => !isEmbedded, withDefaultContent),
  branch(({ isEmbedded }) => isEmbedded, withEmbeddedContent),
  withScroll,
  withLoader,
  withNativeLoadingTrigger
);
