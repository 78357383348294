import { compose, lifecycle, renderNothing } from 'recompose';
import { withRequiredRedirect } from './content/with-required-redirect';

// Moved to a separate component to avoid Content re-rendering
export const RequiredActivityController = compose(
  withRequiredRedirect,
  lifecycle({
    componentDidMount() {
      const { processRequiredActivityRedirect } = this.props;

      processRequiredActivityRedirect();
    }
  })
)(renderNothing);
