import { get, post } from './utils';
import { STATIC } from './utils/constants';

const getMarketingLists = async () => get('/marketing-lists', STATIC);
const getConsentList = async () => get('/consents', STATIC);
const getMarketplacePartners = async () => get('/market-place/partners', STATIC);
const getQuestions = async type => get(`/questions/${type}`, STATIC);
const getQuestion = async ({ type, slug }) => get(`/questions/${type}/${slug}`, STATIC);
const getPensionProvider = async value => get(`/pension-providers?query=${encodeURI(value)}`, STATIC);
const getPensionDifficultyIndicators = async () => get('/pension-providers/difficulty-indicators', STATIC);
const getDataDictionary = async ({ type, introducerCode = 'default' }) =>
  get(`/data-dictionary/${type}?introducerCode=${introducerCode}`, STATIC);
const getChecklist = async type => get('/checklist', STATIC);
const generateReport = async ({ data, templateKey }) => {
  return post('/reports/generate', {
    data,
    template: templateKey
  });
};

export const configuration = {
  getMarketingLists,
  getConsentList,
  getMarketplacePartners,
  getQuestions,
  getPensionProvider,
  getPensionDifficultyIndicators,
  getQuestion,
  getDataDictionary,
  getChecklist,
  generateReport
};
