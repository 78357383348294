import React, { useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import styles from '../../../../../styles/components/desktop-application-header.module.scss';
import { ConfigService } from '../../../../../services/config-service';
import { Grid, Column } from '../../../grid';
import { SkipLink } from '../../../skip-link';
import { withApplicationHeader } from '../../component';
import { HeaderIcon } from '../../default';
import { Logo as AppLogo } from '../../components/logo';
import { CollapsedNav } from './collapsed-nav';
import { DefaultNav } from './default-nav';

export const ApplicationHeader = React.memo(({ className }) => {
  const { buttons } = ConfigService.get('NAVIGATION_BAR', {});
  const [expanded, setExpanded] = useState(false);
  const disableNavigation = useSelector(state => state.navigation.disableNavigation);

  const toggle = () => setExpanded(!expanded);
  const showNavigations = !disableNavigation && !!buttons?.length;
  const showCollapsedNavigation = expanded && !disableNavigation;

  return (
    <>
      <header className={classnames(styles.header, className)}>
        <SkipLink />
        <Grid>
          <Column>
            <div className={styles.container}>
              <AppLogo className={styles.logo} />
              {showNavigations ? (
                <nav>
                  <DefaultNav items={buttons} />
                  <div className={styles.icons}>
                    <HeaderIcon
                      className={classnames(styles.toggle, { [styles.expanded]: expanded })}
                      onClick={toggle}
                      name={expanded ? 'nav-exit' : 'nav-menu'}
                    />
                  </div>
                </nav>
              ) : null}
            </div>
          </Column>
        </Grid>
      </header>
      {showCollapsedNavigation ? <CollapsedNav expanded={expanded} items={buttons} toggle={setExpanded} /> : null}
    </>
  );
});

export const DesktopHeader = withApplicationHeader(ApplicationHeader);
