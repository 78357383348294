import { branch, renderComponent } from 'recompose';
import React from 'react';
import { compose } from 'redux';
import classnames from 'classnames';
import { ConfigService } from '../../services/config-service';
import { createCustomPageProps } from '../../helpers/utils';
import { QUESTION } from '../../helpers/navigation';
import { withQuestionSection } from '../../pages/question/hocs/with-question-section';
import styles from '../../styles/templates/section-page.module.scss';
import { Slot } from '../../components/slots';
import { CloseButton } from '../../components/layout/application-header/components/close-button';
import { Header } from '../../components/layout/page-header';
import { ProgressIndicator } from '../../components/progress-indicator';
import { TBody, Template, TImage } from '../../components/template';
import { Column } from '../../components/layout/grid';
import { Footer } from '../../components/layout/page-footer';
import { asTemplate } from '../../components/layout/with-layout';
import { DigitalCoachWidget } from '../../widgets/digital-coach-widget';
import { Loader } from '../../components/with-loader';

const Component = React.memo(
  ({
    pathname,
    currentQuestion: {
      heading,
      subHeading,
      identifier,
      isPager,
      pageNumber,
      pagesTotal,
      text,
      hideCloseButton = false,
      headerImage,
      backgroundPicture
    }
  }) => {
    const { PROGRESS_INDICATOR, pageHeader } = ConfigService.get('QUESTIONS', {
      PROGRESS_INDICATOR: {}
    });

    return (
      <>
        {!hideCloseButton && (
          <Slot name='top-navigation-right'>
            <CloseButton />
          </Slot>
        )}
        {heading && (
          <Header
            className={styles.header}
            heading={heading}
            subheading={subHeading}
            category={identifier}
            image={headerImage}
            type={pageHeader?.type}
            backgroundPosition={pageHeader?.backgroundPosition}
          />
        )}
        {isPager && (
          <ProgressIndicator
            pageNumber={pageNumber}
            pagesTotal={pagesTotal}
            type={PROGRESS_INDICATOR?.type}
            edge={PROGRESS_INDICATOR?.edge}
            grid
          />
        )}
        <Template className={styles.body} data-atid={createCustomPageProps(pathname, '/').full}>
          {backgroundPicture && <TImage renderAs='section' src={backgroundPicture} className={styles.image} />}
          {text && <TBody html={text} className={classnames(styles.content)} />}
          <Column>
            <DigitalCoachWidget withLoader />
          </Column>
        </Template>
        <Footer pageType='section' />
      </>
    );
  }
);

export const SectionPageTemplate = compose(
  withQuestionSection,
  branch(({ currentSection, loading, isSkipped }) => !currentSection || isSkipped || loading, renderComponent(Loader)),
  asTemplate(QUESTION)
)(Component);
