import DOMPurify from 'dompurify';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';

const REGEXP_ESCAPE = {
  '[': true,
  '\\': true,
  '^': true,
  $: true,
  '.': true,
  '|': true,
  '?': true,
  '*': true,
  '+': true,
  '(': true,
  ')': true
};

const ESCAPE_MAP = {
  ' ': ['&#32;', '&#032;', '&nbsp;'],
  '!': ['&#33;', '&#033;'],
  '"': ['&#34;', '&#034;', '&quot;'],
  '#': ['&#35;', '&#035;'],
  $: ['&#36;', '&#036;'],
  '%': ['&#37;', '&#037;'],
  '&': ['&#38;', '&#038;', '&amp;'],
  [`'`]: ['&#39;', '&#039;', '&apos;'],
  '(': ['&#40;', '&#044;'],
  ')': ['&#41;', '&#041;'],
  '*': ['&#42;', '&#042;'],
  '+': ['&#43;', '&#043;'],
  ',': ['&#44;', '&#044;'],
  '-': ['&#45;', '&#045;', '&#8211;'],
  '.': ['&#46;', '&#046;'],
  '/': ['&#47;', '&#047;'],
  ':': ['&#58;', '&#058;'],
  ';': ['&#59;', '&#059;'],
  '<': ['&#60;', '&#060;', '&lt;'],
  '>': ['&#62;', '&#062;', '&gt;'],
  '?': ['&#63;', '&#063;'],
  '@': ['&#64;', '&#064;'],
  '[': ['&#91;', '&#091;'],
  '\\': ['&#92;', '&#092;'],
  ']': ['&#93;', '&#093;'],
  '^': ['&#94;', '&#094;'],
  _: ['&#95;', '&#095;'],
  '`': ['&#96;', '&#096;'],
  '{': '&#123;',
  '|': '&#124;',
  '}': '&#125;',
  '~': '&#126;',
  [`’`]: '&#8217;'
};

const UNESCAPE_MAP = ObjectShim.entries(ESCAPE_MAP).reduce((acc, [key, value]) => {
  if (Array.isArray(value)) {
    value.forEach(value => {
      acc[value] = key;
    });
  } else {
    acc[value] = key;
  }

  return acc;
}, {});

const createEscaper = map => {
  const escaper = match => (Array.isArray(map[match]) ? map[match][0] : map[match]);

  const matches = Object.keys(map)
    .map(char => (REGEXP_ESCAPE[char] ? `\\${char}` : char))
    .join('|');

  const source = '(?:' + matches + ')';
  const testRegexp = RegExp(source);
  const replaceRegexp = RegExp(source, 'g');

  return (string = '') => (testRegexp.test(string) ? string.replace(replaceRegexp, escaper) : string);
};

export const escape = createEscaper(ESCAPE_MAP);
export const unescape = createEscaper(UNESCAPE_MAP);

export const sanitizeHtml = (html, config = {}) => {
  const { ADD_ATTR = [], ADD_TAGS = [], ...CONFIG } = config;

  return DOMPurify.sanitize(html, {
    ADD_ATTR: ['target', 'href', ...ADD_ATTR],
    ADD_TAGS: [...ADD_TAGS],
    ...CONFIG
  });
};
export const sanitizeString = string => unescape(string);
