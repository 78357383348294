import { parse } from 'query-string';
import { users } from '../api/users';
import { saveMarketingInfo } from '../api/marketing-info';

export class MarketingInfoService {
  static async processURLAttributes(search) {
    const {
      utm_source: source,
      utm_appversion: appversion,
      utm_device: device,
      utm_medium: medium,
      utm_campaign: campaign,
      utm_content: content,
      utm_deviceversion: deviceversion,
      firebaseUserID
    } = parse(search);

    if ((source && source !== 'affiliate') || appversion || device) {
      await saveMarketingInfo({
        utmSource: source,
        utmMedium: medium,
        utmCampaign: campaign,
        utmContent: content,
        utmAppVersion: appversion,
        utmDevice: device,
        utmDeviceVersion: deviceversion
      });
    }

    if (firebaseUserID) {
      await users.updateUser({ firebaseToken: firebaseUserID });
      // lif-2311
      // localStorage.setItem('firebaseToken', firebaseUserID);
    }
  }
}
