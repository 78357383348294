import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose, branch, renderComponent } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { ConfigService } from '../../services/config-service';
import { InputButtonWithIcon as ButtonWithIcon } from '../../components/buttons/button-with-icon';
import { getNextActivity, toggleCoachForcedLoading } from '../../store/reducers/digital-coach';
import { sendStatus } from '../../store/reducers/status-data';
import { sendContentStatus } from '../../store/reducers/content-interaction';
import { sendAdditionalUserAttributes } from '../../store/reducers/user-attributes/actions';
import { statusData } from '../../api/status-data';
import { openLink } from '../../helpers/utils';
import {
  TYPE_ORIGIN_RATE_APP,
  TYPE_ORIGIN_SNOOZE,
  TYPE_ORIGIN_UPDATE_MARKETING_LISTS
} from '../../helpers/digital-coach/constants';
import { SnoozeButton } from './buttons/snooze';
import { MarketingButton } from './buttons/marketing-preferences';
import { CustomButton } from './buttons/custom';
import { RateAppButton } from './buttons/rate';
import { ContentLinkAction } from './buttons/content-link';

export const CustomAction = compose(
  branch(({ originType }) => originType === TYPE_ORIGIN_SNOOZE, renderComponent(SnoozeButton)),
  branch(({ originType }) => originType === TYPE_ORIGIN_UPDATE_MARKETING_LISTS, renderComponent(MarketingButton)),
  branch(({ originType }) => originType === TYPE_ORIGIN_RATE_APP, renderComponent(RateAppButton))
)(CustomButton);

const ReloadActionComponent = React.memo(
  ({
    label,
    ariaLabel,
    activityKey,
    statusKey,
    sendStatus,
    attributes,
    getNextActivity,
    sendAdditionalUserAttributes,
    toggleCoachForcedLoading,
    buttonType = 'major'
  }) => {
    const reloadTimeout = ConfigService.get('COACH.reloadTimeout', 1500);

    const onClick = async () => {
      toggleCoachForcedLoading(true);
      setTimeout(() => toggleCoachForcedLoading(false), reloadTimeout);

      if (attributes && !ObjectShim.isEmpty(attributes)) {
        await sendAdditionalUserAttributes(ObjectShim.deepen(attributes), true);
      }
      if (activityKey) {
        sendStatus(activityKey, statusKey, getNextActivity);
      } else {
        await statusData.recalculateActivity();
        getNextActivity();
      }
    };

    return (
      <ButtonWithIcon onClick={onClick} type={buttonType} ariaLabel={ariaLabel}>
        {label}
      </ButtonWithIcon>
    );
  }
);

export const ReloadAction = connect(null, {
  sendStatus,
  getNextActivity,
  sendAdditionalUserAttributes,
  toggleCoachForcedLoading
})(ReloadActionComponent);

export const ActionComponent = React.memo(
  ({
    label,
    ariaLabel,
    link,
    isExternal,
    activityKey,
    statusKey,
    attributes,
    buttonType = 'major',
    sendAdditionalUserAttributes,
    sendStatus
  }) => {
    const history = useHistory();

    const onClick = useCallback(async () => {
      if (attributes && !ObjectShim.isEmpty(attributes)) {
        await sendAdditionalUserAttributes(ObjectShim.deepen(attributes), true);
      }

      if (activityKey && statusKey) {
        await sendStatus(activityKey, statusKey);
      }

      return openLink(link, history, isExternal);
    }, [link, attributes, history, isExternal, activityKey, statusKey, sendStatus, sendAdditionalUserAttributes]);

    return (
      <ButtonWithIcon onClick={onClick} type={buttonType} ariaLabel={ariaLabel}>
        {label}
      </ButtonWithIcon>
    );
  }
);
export const Action = connect(null, {
  sendStatus,
  sendAdditionalUserAttributes
})(ActionComponent);

export const ContentLink = compose(
  connect(({ content: { contentPages } }) => ({ contentPages }), { sendContentStatus })
)(ContentLinkAction);
