import React from 'react';
import { compose, lifecycle, withHandlers } from 'recompose';
import classnames from 'classnames';
import styles from '../../../styles/components/inputs/radio-group.module.scss';
import { Textarea } from '../textarea';
import { InputGroup } from '../input-group';
import { withContainer } from '../input-field';
import { InputRadio } from './radio';

export const RadioGroup = React.memo(
  ({ plainValue, userAttributeField, onChange, onTextChange, className, value, items = [] }) => {
    return (
      <InputGroup heir className={classnames(styles.wrapper, className)}>
        {items.map(item => (
          <Radio
            key={item.name}
            value={value}
            data={item}
            plainValue={plainValue}
            userAttributeField={userAttributeField}
            onChange={onChange}
            onTextChange={onTextChange}
          />
        ))}
      </InputGroup>
    );
  }
);

const RadioComponent = React.memo(({ data, plainValue: usePlainValue, onChange, onTextChange, getInputValue }) => {
  const { name, label, explainer, value: boundValue, type, maxLength, placeholder, helpModal, additionalValues } = data;

  const { checked, text } = getInputValue();

  return (
    <>
      <InputRadio
        label={label}
        informer={helpModal}
        explainer={explainer}
        checked={checked}
        onChange={onChange({ name, boundValue, usePlainValue, additionalValues })}
        className={classnames(styles.unchecked, { [styles.checked]: checked })}
      />
      {checked && type && (
        <Textarea placeholder={placeholder} maxLength={maxLength} value={text} onChange={onTextChange(name)} />
      )}
    </>
  );
});

const Radio = compose(
  withHandlers({
    getInputValue:
      ({ plainValue: usePlainValue, value = {}, data: { name, value: boundValue } }) =>
      () => {
        if (usePlainValue) {
          return {
            checked: value === boundValue,
            text: boundValue
          };
        }

        return (
          value[name] ?? {
            text: '',
            checked: false
          }
        );
      }
  }),
  lifecycle({
    componentDidMount() {
      const { data, plainValue: usePlainValue, onChange, getInputValue } = this.props;
      const { name, additionalValues, value: boundValue } = data;

      // Add additional data to already checked radio
      if (getInputValue().checked && additionalValues) {
        onChange({ name, boundValue, usePlainValue, additionalValues })();
      }
    }
  })
)(RadioComponent);

export const InputRadioGroup = withContainer(RadioGroup);
