import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { BugsnagService } from './bugsnag';

export class ConfigService {
  static get(path, defaultValue) {
    const CONFIG = window.CONFIG;

    return ObjectShim.getNested(CONFIG, path, path ? defaultValue : CONFIG, (key, path) => {
      const error = `${key} is not exist on ${path}`;

      // eslint-disable-next-line no-console
      console.warn(error, 'default value as', defaultValue, 'is returned instead.');

      BugsnagService.notify(error);
    });
  }
}
