import React from 'react';
import { branch, compose, renderNothing } from 'recompose';
import styles from '../../styles/components/modal-header.module.scss';
import { AffiliateIcon } from '../icons/affiliate-icon';
import { Image } from '../images/image';
import { PurifiedHtmlContainer } from '../layout/purified-html-container';

const ModalHeaderComponent = React.memo(({ html, leftImage, title, subTitle, children, onClose }) => {
  return (
    <hgroup className={styles.container}>
      <PurifiedHtmlContainer html={html} className={styles.wrapper}>
        {(title || onClose) && (
          <h2 className={styles.label}>
            {leftImage && <Image src={leftImage} alt={leftImage} className={styles['left-image']} />}
            {title && <span className={styles.title}>{title}</span>}
            {onClose && <AffiliateIcon name='nav-exit' className={styles.icon} onClick={onClose} />}
          </h2>
        )}
        {subTitle && <p className={styles.subtitle}>{subTitle}</p>}
      </PurifiedHtmlContainer>
      {children}
    </hgroup>
  );
});

export const ModalHeader = compose(
  branch(({ html, title, subTitle, children, onClose }) => {
    return [html, title, subTitle, children, onClose].filter(Boolean).length < 1;
  }, renderNothing)
)(ModalHeaderComponent);
