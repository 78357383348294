import { TYPE_TO_ACTIVITY_KEY } from './constants';

const FEEDBACK_ITEMS = ['like', 'dislike'];
const SAVED_ITEMS = 'saved';

export const getFeedbackAndFavoritesFromAction = payload => {
  return payload.reduce(
    (acc, item) => {
      const { activityStatusKey, activityStatusOrder, key } = item;

      if (FEEDBACK_ITEMS.includes(activityStatusKey) && key) {
        acc.feedback[key] = item;

        return acc;
      }

      if (activityStatusKey === SAVED_ITEMS && key) {
        acc.savedItems[key] = item;

        return acc;
      }

      const prevStatusOrder = acc.contentStatuses[key]?.activityStatusOrder;

      if (!acc.contentStatuses[key] || prevStatusOrder < activityStatusOrder) {
        acc.contentStatuses[key] = {
          key: activityStatusKey,
          order: activityStatusOrder
        };

        return acc;
      }

      return acc;
    },
    { feedback: {}, savedItems: {}, contentStatuses: {} }
  );
};

export const getAdditionalDataByContentType = ({ contentType, key, slug, id, title }) => {
  switch (contentType) {
    case TYPE_TO_ACTIVITY_KEY.article:
    case TYPE_TO_ACTIVITY_KEY.blog:
      return {
        wpId: id,
        name: title,
        wpSlug: slug,
        key
      };
    default:
      return { key };
  }
};
