import classnames from 'classnames';
import React, { useRef } from 'react';
import uuid from 'uuid';
import { withPropsOnChange } from 'recompose';
import styles from '../../styles/components/input-field.module.scss';
import { InputLabel, InputOptionLabel } from './input-label';
import { InputError } from './input-error';
import { Explainer } from './form-explainer';

export const withAnchor = withPropsOnChange(['id', 'name'], ({ id, name }) => ({
  id: id ?? uuid(name)
}));
export const withContainer = Component => props =>
  (
    <div className={classnames(styles.container, props.className)}>
      <div className={styles.controller}>
        <Component {...props} />
      </div>
    </div>
  );
export const withLabel =
  ({ className, layout } = {}) =>
  Component =>
    React.memo(({ id, label, informer, withPreventingDefault, className: propsClassName, ...props }) => (
      <div className={styles['label-container']}>
        <InputLabel
          htmlFor={id}
          label={label}
          informer={informer}
          className={classnames(className, propsClassName)}
          layout={layout}
          isRequired={props.isRequired}
          withPreventingDefault={withPreventingDefault}
        >
          <Component id={id} {...props} />
        </InputLabel>
      </div>
    ));

export const withError = Component =>
  React.memo(({ ...props }) => (
    <>
      <Component {...props} />
      {props.error && <InputError errorId={`${props.id}_error`}>{props.error}</InputError>}
    </>
  ));
export const withExplainer = Component =>
  React.memo(({ ...props }) => (
    <>
      <Component {...props} />
      {props.explainer && (
        <Explainer explainerId={`${props.id}_explainer`} className={styles.explainer}>
          {props.explainer}
        </Explainer>
      )}
    </>
  ));

export const withOptionLabel =
  ({ className, layout } = {}) =>
  Component =>
    React.memo(({ id, label, informer, withPreventingDefault, className: propsClassName, ...props }) => (
      <div className={styles['label-container']}>
        <InputOptionLabel
          htmlFor={id}
          label={label}
          informer={informer}
          className={classnames(className, propsClassName)}
          layout={layout}
          isRequired={props.isRequired}
          withPreventingDefault={withPreventingDefault}
          explainer={props.explainer}
        >
          <Component id={id} {...props} />
        </InputOptionLabel>
      </div>
    ));

export const withOptionRowClick = Component => props => {
  const inputRef = useRef(null);
  // Manually passes input's checked state to proper storing submit values.
  const handleClick = e =>
    !props.disabled && props.onChange({ ...e, target: { ...e.target, checked: !inputRef?.current?.checked } });

  return (
    <div id={`${props.id}-option-row-click`} className='fill-width' onClick={handleClick}>
      <Component inputRef={inputRef} withPreventingDefault {...props} />
    </div>
  );
};
