import { connect } from 'react-redux';
import { branch, compose, renderNothing, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { shareRoutesCheck } from '../../../helpers/regex';
import { CHECKPOINT } from '../../../helpers/navigation';
import { toggleModal } from '../../../store/reducers/modals';
import { ConfigService } from '../../../services/config-service';
import { withEmbeddedContext } from '../with-embedded-context';
import { isVisible, not } from '../with-layout';
import { DefaultApplicationHeader } from './default';

const android = window.NativeShare;
const iOS = window.webkit && window.webkit.messageHandlers.NativeShare;

const SHARE =
  android ||
  (iOS && {
    share: (url, title) => iOS.postMessage({ url, title })
  });

const mapStateToProps = (state, { location, isEmbedded }) => {
  const {
    sections: { sidebar, topSectionNavigation }
  } = state.layout;

  const {
    showNavigation,
    headerButtons: { editButton, helpButton }
  } = state.navigation;

  const SHARING = ConfigService.get('SHARING');

  return {
    showNavigation,
    withNavigationOffset: topSectionNavigation && !sidebar,
    editButton: editButton[location.pathname],
    helpButton: helpButton[location.pathname],
    shareButton: SHARING?.show && shareRoutesCheck.test(location.pathname) && !isEmbedded
  };
};

export const withApplicationHeader = compose(
  isVisible({
    forTemplates: [not(CHECKPOINT)]
  }),
  withRouter,
  withEmbeddedContext,
  connect(mapStateToProps, { toggleModal }),
  branch(({ showNavigation }) => !showNavigation, renderNothing),
  withHandlers({
    handleEditButton:
      ({ editButton, history }) =>
      () => {
        editButton.callback ? editButton.callback() : history.push(editButton.url);
      },
    handleHelpButton:
      ({ helpButton, history }) =>
      () => {
        helpButton.callback ? helpButton.callback() : history.push(helpButton.url);
      },
    handleShareButton:
      ({ toggleModal }) =>
      () => {
        const { SHARING } = window.CONFIG;

        if (SHARE?.share) {
          SHARE.share(window.location.href, SHARING.message);
        } else {
          toggleModal(true, 'sharing');
        }
      }
  })
);

export const DefaultHeader = withApplicationHeader(DefaultApplicationHeader);
