// TODO: Consider to make it multi-dimensional
export const getTwoDimensionalArrayIterator = function* getTwoDimensionalArrayIterator(target, key = '') {
  let iterable = [...target],
    buffer = [];

  do {
    // fill the buffer until get enough chunks to run main loop or iterable is empty
    while (iterable.length > 0 && buffer.length < 2) {
      buffer = buffer.concat(iterable.shift()[key] ?? []);
    }

    yield buffer.shift();
  } while (buffer.length);
};

/**
 * Sort array of objects by weight property from lowest to highest.
 * @param {array} array - list of items to sort
 * @return {array}
 */
export const sortByWeight = array => array.sort((x, y) => x.weight - y.weight);
