import React from 'react';
import classnames from 'classnames';
import styles from '../../../styles/components/application-header.module.scss';
import { DEVICE } from '../../../helpers/user-agent';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { useSubscribe } from '../../slots';
import { SkipLink } from '../skip-link';
import { Sticky } from '../sticky';
import { useLayoutSection } from '../with-layout';
import { LAYOUT_SECTIONS } from '../../../helpers/layout/sections';
import { NOT_FOUND } from '../../../helpers/navigation';
import { TopSectionNavigationBar } from './components/top-navigation';
import { BackButton } from './components/back-button';
import { AppLogo } from './components/logo';

export const HeaderIcon = React.memo(({ name, to, onClick, className, ariaDisabled }) => (
  <AffiliateIcon
    to={to}
    name={name}
    className={classnames(styles.icon, className)}
    onClick={onClick}
    ariaDisabled={ariaDisabled}
  />
));

export const DefaultApplicationHeader = React.memo(
  ({
    className,
    editButton,
    helpButton,
    shareButton,
    withNavigationOffset,
    handleEditButton,
    handleHelpButton,
    handleShareButton,
    template
  }) => {
    const ref = useLayoutSection(LAYOUT_SECTIONS.TOP_NAVIGATION, 'clientHeight');

    const leftSlot = useSubscribe('top-navigation-left');
    const rightSlot = useSubscribe('top-navigation-right');

    return (
      <Sticky ref={ref} renderAs='header' className={className} nested={false}>
        <SkipLink />
        <div className={styles.container}>
          <TopSectionNavigationBar className={styles.navigation} />
          <div className={classnames(styles.body, { [styles.offset]: withNavigationOffset })}>
            <div className={classnames(styles.icons, styles.left)}>
              <BackButton />
              <div className={styles.slot} ref={leftSlot} />
            </div>
            <AppLogo className={styles.logo} />
            <div className={classnames(styles.icons, styles.right)}>
              <div className={styles.slot} ref={rightSlot} />
              {editButton?.display && <HeaderIcon name='nav-restart' onClick={handleEditButton} />}
              {helpButton?.display && <HeaderIcon name='nav-help' onClick={handleHelpButton} />}
              {shareButton && template !== NOT_FOUND && (
                <HeaderIcon name={`share-${DEVICE.IOS() ? 'ios' : 'generic'}`} onClick={handleShareButton} />
              )}
            </div>
          </div>
        </div>
      </Sticky>
    );
  }
);
