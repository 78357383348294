import React from 'react';
import compose from 'recompose/compose';
import classnames from 'classnames';
import styles from '../../../styles/components/checkbox.module.scss';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { withAnchor, withContainer, withExplainer, withLabel, withOptionRowClick } from '../input-field';

export const Input = React.memo(({ className, inputRef, ...props }) => (
  <input ref={inputRef} type='checkbox' className={classnames(styles.input, className, 'thrown-away')} {...props} />
));

const Component = React.memo(
  ({ id, icon = <AffiliateIcon name='checkbox-done' />, checked = false, pulse, ...props }) => (
    <div className={props.className}>
      <Input id={id} className={styles.field} checked={checked} {...props} />
      <div className={classnames(styles.icon, { pulse })}>{icon}</div>
    </div>
  )
);

export const Checkbox = compose(withAnchor, withLabel)(Component);
export const InputCheckbox = compose(
  withAnchor,
  withContainer,
  withOptionRowClick,
  withExplainer,
  withLabel({ className: styles.label, layout: styles.container })
)(Component);
