import React from 'react';
import { compose } from 'recompose';
import styles from '../../../styles/components/select.module.scss';
import { popper } from '../../../helpers/inputs/select-popover';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { withAnchor, withContainer, withError, withExplainer, withLabel } from '../input-field';
import { withPopover } from '../popovers/list';

const Component = React.memo(({ placeholder, label, value = placeholder, displayValue, ...props }) => (
  <button className={styles.field} {...props}>
    <span className={styles.value}>{displayValue || value}</span>
    <AffiliateIcon className={styles.icon} name='select-indicator' inline />
  </button>
));

export const Select = withPopover({ popper })(Component);
export const withSelectLabel = withLabel({ className: styles.label, layout: styles.container });
export const InputSelect = compose(withAnchor, withContainer, withExplainer, withError, withSelectLabel)(Select);
