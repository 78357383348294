import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import { parseDefaultValue } from '../helpers/parse-default-value';

const mapExistingValue = ({ existingValue }) => {
  if (!isNil(existingValue)) return existingValue;
};

export const withValue = compose(
  withState('value', 'setValue', mapExistingValue),
  withHandlers({
    setFormValue: ({ setFormValues, ...props }) => setFormValues(props)
  }),
  lifecycle({
    async componentDidMount() {
      const {
        type,
        value,
        identifier,
        setValue,
        userAttributes,
        defaultValue,
        setFormValue,
        checkDisable,
        additionalValues,
        setSubmitAdditionalValues
      } = this.props;

      let inputVal = value;

      //here we set calculated default value if there is no value in user attributes
      if (!inputVal && !isNil(defaultValue)) {
        inputVal = await parseDefaultValue(userAttributes, defaultValue, { identifier });

        setValue(inputVal);
      }

      if (!['groupParamsMarketingList', 'groupParams', 'button', 'infoButton'].includes(type)) {
        setFormValue(inputVal);
        checkDisable(inputVal);
      }

      if (additionalValues) {
        setSubmitAdditionalValues(additionalValues);
      }
    }
  })
);
