import React from 'react';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { QuestionInfoButton } from '../../button-info';

const PRE_SUBMIT_BUTTON_TYPES = {
  infoButton: 'infoButton'
};

const PreSubmitButton = React.memo(({ type, ...props }) => {
  switch (type) {
    case PRE_SUBMIT_BUTTON_TYPES.infoButton:
    default:
      return <QuestionInfoButton {...props} />;
  }
});

export const withPreSubmitButton = Component =>
  React.memo(({ preSubmitButton, ...props }) => (
    <>
      <Component {...props} />
      {ObjectShim.isObject(preSubmitButton) ? <PreSubmitButton {...preSubmitButton} /> : null}
    </>
  ));
