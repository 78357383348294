import React from 'react';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { compose, lifecycle, branch, withProps, renderComponent, withState } from 'recompose';
import { sortByWeight } from '../../helpers/array';
import styles from '../../styles/widgets/digital-coach-widget/digital-coach.module.scss';
import { CoachMessage } from '../../components/coach-message';
import { InputGroup } from '../../components/inputs/input-group';
import { withCustomSpinner } from '../../components/with-loader';
import { enableCoachLoader, getNextActivity, getSpecificActivity } from '../../store/reducers/digital-coach';
import { UserService } from '../../services/user-service';
import {
  TYPE_BUTTON_ACTION_CALL_CUSTOM_ACTION,
  TYPE_BUTTON_ACTION_RELOAD,
  TYPE_CONTENT_LINK,
  TYPE_ORIGIN_POLL,
  TYPE_ORIGIN_RATE_APP,
  TYPE_SKIP_SECTION,
  TYPE_FAQ_MODAL
} from '../../helpers/digital-coach/constants';
import { useConfirm } from '../../components/layout/popups/confirm';
import { isModuleCompleted } from '../../helpers/custom-page/is-completed';
import { withEmbeddedContext } from '../../components/layout/with-embedded-context';
import { withCancellableHandlers } from '../../pages/custom-page/with-cancellable-handlers';
import {
  withStaticWidgetLoadingLifecycle,
  withWidgetLoadingHandlers
} from '../../components/layout/content/with-native-loading-state';
import { SET_WIDGET_STATE_ASYNC_SUCCESS } from '../../store/reducers/application';
import { FAQModalButton } from './buttons/faq-modal';
import { DigitalCoachDisplayController } from './display-controller';
import { Action, ReloadAction, CustomAction, ContentLink } from './actions';
import { RateAppButton } from './buttons/rate';
import { SimplePoll } from './simple-poll';
import { SkipSectionAction } from './buttons/skip-section';

export const DigitalCoachAction = compose(
  branch(({ actionType }) => actionType === TYPE_ORIGIN_RATE_APP, renderComponent(RateAppButton)),
  branch(({ actionType }) => actionType === TYPE_BUTTON_ACTION_RELOAD, renderComponent(ReloadAction)),
  branch(({ actionType }) => actionType === TYPE_BUTTON_ACTION_CALL_CUSTOM_ACTION, renderComponent(CustomAction)),
  branch(({ actionType }) => actionType === TYPE_ORIGIN_POLL, renderComponent(SimplePoll)),
  branch(({ actionType }) => actionType === TYPE_SKIP_SECTION, renderComponent(SkipSectionAction)),
  branch(({ actionType }) => actionType === TYPE_CONTENT_LINK, renderComponent(ContentLink)),
  branch(({ actionType }) => actionType === TYPE_FAQ_MODAL, renderComponent(FAQModalButton))
)(Action);

const DigitalCoachWidgetComponent = React.memo(
  ({ activity: { digitalCoachMessage: message, digitalCoachActions: actions, options }, isMounted }) => {
    const [confirm, handleConfirm] = useConfirm({
      props: {
        acceptType: 'major',
        declineType: 'minor'
      }
    });

    return (
      <>
        <DigitalCoachDisplayController type={options?.slideUp} isMounted={isMounted}>
          <>
            {message && <CoachMessage html={message} />}
            {actions && (
              <InputGroup type='coach'>
                {sortByWeight(actions).map(action => (
                  <DigitalCoachAction key={uuid('digital-coach-action')} onConfirm={handleConfirm} {...action} />
                ))}
              </InputGroup>
            )}
          </>
        </DigitalCoachDisplayController>
        {confirm}
      </>
    );
  }
);

DigitalCoachWidgetComponent.displayName = 'DigitalCoachWidgetComponent';

const nextMapStateToProps = state => {
  const activity = state.digitalCoach.nextActivity;
  const { forcedLoading, loading } = state.digitalCoach;
  const { activityKey, digitalCoachActions } = activity;

  // no need to call getNextActivity in componentDidUpdate in case actionType is simplePoll because
  // it is needed to have possibility to send status update without updating activity
  const isPoll = digitalCoachActions?.some(({ actionType }) => actionType === TYPE_ORIGIN_POLL);

  return {
    activity,
    loading: forcedLoading || loading,
    isCompleted: activityKey && isModuleCompleted(state.statusData, activityKey) && !isPoll
  };
};

const StaticDigitalCoachWidget = compose(
  withStaticWidgetLoadingLifecycle({ condition: true }),
  withProps(() => {
    const {
      ACCOUNT: { DIGITAL_COACH_ACTIVITY }
    } = window.CONFIG;

    return {
      activity: DIGITAL_COACH_ACTIVITY
    };
  })
)(DigitalCoachWidgetComponent);

StaticDigitalCoachWidget.displayName = 'StaticDigitalCoachWidget';

export const DigitalCoachWidget = compose(
  connect(nextMapStateToProps, { getNextActivity, enableCoachLoader }),
  withState('isMounted', 'setMounted', false),
  withEmbeddedContext,
  withCancellableHandlers({
    getNextActivityCancellable:
      ({ getNextActivity }) =>
      async () =>
        getNextActivity()
  }),
  withWidgetLoadingHandlers,
  lifecycle({
    componentDidMount() {
      const {
        getNextActivityCancellable,
        setMounted,
        setWidgetLoadedState,
        isEmbedded,
        postResizeMessage,
        withLoader,
        enableCoachLoader
      } = this.props;

      if (withLoader) {
        enableCoachLoader();
      }
      const fetchNextActivity = async () => await getNextActivityCancellable();
      fetchNextActivity()
        .then(() => {
          setMounted(true);
          setWidgetLoadedState(SET_WIDGET_STATE_ASYNC_SUCCESS, true);
        })
        .finally(() => {
          if (isEmbedded) {
            postResizeMessage();
          }
        });
    },
    componentDidUpdate() {
      const { getNextActivity, isCompleted } = this.props;

      if (isCompleted) {
        const fetchNextActivity = async () => await getNextActivity();
        fetchNextActivity();
      }
    }
  }),
  branch(() => UserService.hasAccount(), renderComponent(StaticDigitalCoachWidget)),
  withCustomSpinner({ className: styles.spinner })
)(DigitalCoachWidgetComponent);

DigitalCoachWidget.displayName = 'DigitalCoachWidget';

const specificMapStateToProps = state => {
  const activity = state.digitalCoach.specificActivity;
  const { forcedLoading, loading } = state.digitalCoach;
  const { activityKey, digitalCoachActions } = activity;

  // no need to call getNextActivity in componentDidUpdate in case actionType is simplePoll because
  // it is needed to have possibility to send status update without updating activity
  const isPoll = digitalCoachActions?.some(({ actionType }) => actionType === TYPE_ORIGIN_POLL);

  return {
    activity,
    loading: forcedLoading || loading,
    isCompleted: activityKey && isModuleCompleted(state.statusData, activityKey) && !isPoll
  };
};

export const SpecificDigitalCoachWidget = compose(
  connect(specificMapStateToProps, { getSpecificActivity }),
  withState('isMounted', 'setMounted', false),
  withWidgetLoadingHandlers,
  lifecycle({
    componentDidMount() {
      const { getSpecificActivity, setMounted, pageIdentifier, setWidgetLoadedState } = this.props;

      const fetchSpecificActivity = async () => await getSpecificActivity(pageIdentifier);
      fetchSpecificActivity().then(() => {
        setMounted(true);
        setWidgetLoadedState(SET_WIDGET_STATE_ASYNC_SUCCESS, true);
      });
    },
    componentDidUpdate() {
      const { getSpecificActivity, isCompleted, pageIdentifier } = this.props;

      if (isCompleted) {
        const fetchSpecificActivity = async () => await getSpecificActivity(pageIdentifier);
        fetchSpecificActivity();
      }
    }
  }),
  withCustomSpinner({ className: styles.spinner })
)(DigitalCoachWidgetComponent);

SpecificDigitalCoachWidget.displayName = 'SpecificDigitalCoachWidget';
