import debounce from 'lodash.debounce';
import uuid from 'uuid';
import { compose, withHandlers, withProps, withPropsOnChange, withState } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { insertVarIntoString } from '../../../../../helpers/insert-var-into-string';
import { withLoadingHandlers } from '../../../../../components/with-loader';

export const withAutoCompleteHandlers =
  ({ valueName, userAttributeName, method }) =>
  Component =>
    compose(
      connect(state => ({ attributes: state.userAttributes })),
      withRouter,
      withLoadingHandlers(false),
      withProps(({ match }) => ({
        valueName,
        identifier: match.params.identifier
      })),
      withState(
        'disabled',
        'setDisabled',
        ({ attributes, identifier }) =>
          !ObjectShim.getNested(attributes, insertVarIntoString(userAttributeName, { identifier }))
      ),
      withPropsOnChange(['submitDisable', 'disabled'], ({ submitDisable, disabled }) => ({
        disabled: submitDisable || disabled
      })),
      withState('autocompleteItems', 'setAutocompleteItems', []),
      withHandlers({
        onAutoCompleteChange:
          ({ turnOnLoading, turnOffLoading, setAutocompleteItems, valueName }) =>
          async value => {
            if (!value) {
              return setAutocompleteItems([]);
            }

            turnOnLoading();

            const response = await method(value);

            if (value && response.ok) {
              const data = await response.json();

              data.results &&
                setAutocompleteItems(
                  data.results.slice(0, 5).map(item => ({
                    id: uuid(),
                    label: item[valueName],
                    value: item
                  }))
                );

              turnOffLoading();
            }
          },
        onChooseAutocompleteItem:
          ({ onChange, setDisabled }) =>
          () =>
          value => {
            setDisabled(false);
            onChange(value);
          }
      }),
      withHandlers({
        onValueChange:
          ({ setDisabled, onChange, onAutoCompleteChange }) =>
          value => {
            setDisabled(true);
            onChange(value);
            onAutoCompleteChange(value);
          }
      }),
      withPropsOnChange(['onAutoCompleteChange'], ({ onAutoCompleteChange, time = 500 }) => ({
        onAutoCompleteChange: debounce(onAutoCompleteChange, time)
      }))
    )(Component);
